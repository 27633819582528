<template>
  <div
    class="login login-signin-on login-3 d-flex flex-row-fluid"
    id="kt_login"
  >
    <div
      class="d-flex flex-center flex-row-fluid bgi-size-cover bgi-position-top bgi-no-repeat"
      style="background-image: url('/assets/media/bg/bg-3.jpg');"
    >
      <div class="login-form text-center p-7 position-relative overflow-hidden">
        <div class="d-flex flex-center mb-10">
            <img
              :src="(getOrganization.data) ? getOrganization.data.logo1Uri : '/assets/media/bipart/logo-bipart-brand.svg'"
              class="h-80px"
              alt="Logo"
            />
        </div>

        <div class="login-signup">
          <div class="mb-2">
            <h1>{{$t("EMAILCONFIRM.TITOLO")}}</h1>
<!--            <div class="text-muted font-weight-bold">-->
<!--              <span class="h3"> {{$t("EMAILCONFIRM.SOTTOTITOLO") /* #key_loc */}}</span>-->
<!--            </div>-->
          </div>
          <div
              v-if="!this.email_confirmed"
              class="mt-10">
            <h2 class="mt-10">{{$t("EMAILCONFIRM.AUTOK")}}</h2>
            <p>{{$t("EMAILCONFIRM.AUTOKDETAIL")}}</p>
          </div>
          <div
              v-if="!this.email_confirmed"
              class="form-group d-flex flex-wrap flex-center">
              <div class="mt-5">
                <b-button
                  class="btn btn-lg btn-primary text-uppercase font-weight-bolder"
                  id="confirm_submit"
                  ref="confirm_submit"
                 @click="emailConfirm">
                  {{$t("EMAILCONFIRM.BLOGIN")}}</b-button>
              </div>
            </div>

        </div>
      </div>
    </div>
  </div>

</template>

<style lang="scss" scoped>
  .spinner.spinner-right {
    padding-right: 3.5rem !important;
  }
  </style>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";

import { commonMixin } from "@/mixins/common";
import {alertMixin} from "@/mixins/alert";
import ApiService from "@/core/services/api.service";

// import Swal from "sweetalert2";
import {REFRESH_TOKEN} from "@/core/services/store/auth.module";
import jwt_decode from "jwt-decode";
import i18nService from "@/core/services/i18n.service";

export default {
  name: "email-confirm",
  mixins: [ commonMixin, alertMixin ],
  data() {
    return {
      ct:'',
      p:'',
      partecipationPath: '/home',
      email_confirmed: false,
      process_id: 0
    };
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters([
      "getOrganization"
    ]),
  },
  mounted() {
    this.ct = this.$route.query.ct
    this.p = this.$route.query.p

    // Costruzione del partecipationPath
    let qs = atob(this.ct).split('&');
    let process_kv = [];

    process_kv = qs.filter( item => {
      return item.includes('processId=');
    });
    if(process_kv.length > 0) {
      this.process_id = process_kv[0].split('=')[1];
      this.partecipationPath = '/process-detail/' + this.process_id;

    }
    console.log('partecipationPath: ', this.partecipationPath);

  },
  methods: {
    async emailConfirm() {
      // set spinner to submit button
      const submitButton = this.$refs["confirm_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      let confirmData = {
        ct: this.ct,
        p: this.p,
        partecipationPath: this.partecipationPath
      }

      let confirmResult = await new Promise((resolve, reject) => {
        ApiService.put(this.$apiResourceOpen + "/public/users/confirm-user-email-by-token", confirmData)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            // console.log('Value err.response: ', err.response);
            this.manageAxiosErrors(err);
            submitButton.classList.remove("spinner", "spinner-light", "spinner-right");
            reject(0);
          });
      });
      console.log(confirmResult);

      if( confirmResult.status == 200 && confirmResult.headers.token !== "BadCredentialsException") {

        let token = confirmResult.headers.token;
        let organization_name = (this.getOrganization.data && this.getOrganization.data.localizations && this.getOrganization.data.localizations.find(x => x.locale == i18nService.getActiveLanguage())) ? this.getOrganization.data.localizations.find(x => x.locale == i18nService.getActiveLanguage()).name : '';
        await this.$store.dispatch(REFRESH_TOKEN, token);

        const nr_user_process = jwt_decode(token).assignedProcesses.length

        // ==>> MEMO <<==
        //  eventualmente aggiungere al controllo nr_user_process > 0 un ulteriore controllo per verificare
        //  che this.process_id appartenga ai processi assegnati all'utente nel token

        if(nr_user_process > 0) {
          // Ricavo il processo
          let process = await this.getProcess(this.process_id);
          console.log('Processo: ', process);
          // console.log('Route to push: ', '/process-detail/' + process_id);

          this.email_confirmed = true;
          this.alertEmailConfirmedOnRegisteredToProcess(organization_name, process.title);
          // await this.$router.push('/personal-space');
          await this.$router.push('/personal-space-activities');
          // await this.$router.push('/process-detail/' + this.process_id);
        } else {
          this.alertEmailConfirmedOnRegisteredToOrganization(organization_name);
          // await this.$router.push('/personal-space');
          await this.$router.push('/personal-space-activities');
          // await this.$router.push('/home');
        }
      } else {
        this.manageAxiosErrors(confirmResult);
        submitButton.classList.remove("spinner", "spinner-light", "spinner-right");
      }

      submitButton.classList.remove("spinner", "spinner-light", "spinner-right");
    },

    async getProcess(process_id) {
      return await new Promise((resolve, reject) => {
        var endpoint = this.generateUrl("processes/" + process_id, true);
        //generateUrl is done
        ApiService.get(this.$apiResource, endpoint)
          .then(res => {
            resolve(res.data);
          })
          .catch(() => {
            reject(0);
          });
      });
    },

  }
}
</script>
